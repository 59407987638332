// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-open-startup-js": () => import("./../../../src/pages/open-startup.js" /* webpackChunkName: "component---src-pages-open-startup-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-sponsor-site-js": () => import("./../../../src/pages/sponsor-site.js" /* webpackChunkName: "component---src-pages-sponsor-site-js" */),
  "component---src-templates-tip-post-js": () => import("./../../../src/templates/tip-post.js" /* webpackChunkName: "component---src-templates-tip-post-js" */),
  "component---src-templates-tips-js": () => import("./../../../src/templates/tips.js" /* webpackChunkName: "component---src-templates-tips-js" */),
  "component---src-templates-video-post-js": () => import("./../../../src/templates/video-post.js" /* webpackChunkName: "component---src-templates-video-post-js" */),
  "component---src-templates-videos-js": () => import("./../../../src/templates/videos.js" /* webpackChunkName: "component---src-templates-videos-js" */)
}

